//
//** Spinner Message Component
//

//== Mixins
@mixin m-build-component--jstree-base() {
    .jstree-default {
        .jstree-anchor {
        }

        .jstree-disabled {
            cursor: not-allowed;
            line-height: auto;
            height: auto;
        }

        .jstree-icon {    
            &.la {
                font-size: 1.5rem;
            }

            &.fa {
                font-size: 1.2rem;
            }
        }
    } 

    .jstree-open > .jstree-anchor > .fa-folder:before {
        margin-left: 2px;
        content: "\f07c"; 
    }

    .jstree-open > .jstree-anchor > .la-folder:before {
        margin-left: 2px;
        content: "\f200"; 
    }

    .jstree-default.jstree-rtl .jstree-last {
        background: transparent;
        background-repeat: no-repeat;
    }

    // context menu
    .vakata-context,
    .vakata-context ul {
        padding: 0;
        min-width: 125px;
        font-size: get-font-size(regular);
        font-family: #{array-get($m--font-families, regular)};

        @include rounded {
            @include border-radius(array-get($m--border-radius, dropdown));
        }

        
            li {
                border: 0;

                a { 
                    padding: 0 10px;       
                    border: 0;
                    line-height: 2.2em;

                    i {
                        display: none;   
                    }

                    .vakata-contextmenu-sep {  
                        display: none;  
                    }

                    span,
                    ins {
                        display: none;
                        border: 0 !important;
                    }
                }
            }

            .vakata-context-hover > a,
            li a:hover {  
                margin: 0;   

                .span,
                .ins {
                    border: 0 !important;
                }
            }
        
    },    

    .vakata-context .vakata-context-separator a,   
    .vakata-context-rtl .vakata-context-separator a {
        margin: 0;
        border: 0;
    }

    .jstree-rename-input {
        outline: none !important;
        padding: 2px 6px !important;
        margin-right: -4px !important;

        @include rounded {
            @include border-radius(array-get($m--forms, input, layout, self, size, default, border-radius));
        }
    }
}

@mixin m-build-component--jstree-skin($skin) {
    .jstree-default {

        .jstree-anchor {
            color: get-color($skin, regular);
        }

        .jstree-icon {   
            color: get-color($skin, icon, '+');
        }

        .jstree-disabled {
            @include opacity(0.7);

            .jstree-icon {   
                color: get-color($skin, icon, '+');
            }
        }

        .jstree-clicked {
            border: 0;
            background: get-color($skin, panel);
            box-shadow:none;
        }

        .jstree-hovered {
            border: 0;
            background-color: get-color($skin, panel, '-');
            box-shadow:none;
        }

        .jstree-wholerow-clicked,
        .jstree-wholerow-clicked {
            background: get-color($skin, panel, '-');
            box-shadow:none;
        }

        .jstree-wholerow-hovered,
        &.jstree-wholerow .jstree-wholerow-hovered {
            border: 0;
            background-color: get-color($skin, panel, '-');
            box-shadow:none;
        }
    }

    .vakata-context,
    .vakata-context ul {
        background: get-color($skin, box);
        @include shadow(get-shadow($skin));
        border: 0;

            li {
                a { 
                    i {
                    }

                    span,
                    ins {
                    }
                }
            }

            .vakata-context-hover > a,
            li a:hover {
                background: get-color($skin, panel, '-');
                color: get-color($skin, regular);
                @include shadow(none);

                .span,
                .ins {
                    border: 0 !important;
                }
            }
       
    }

    .vakata-context .vakata-context-separator a,   
    .vakata-context-rtl .vakata-context-separator a {
        margin: 0;
        border: 0;
    }

    .jstree-rename-input {
        background-color: get-color($skin, box) !important;
        border: 1px solid get-color($skin, panel) !important;
    }
}

//== Build

@include m-build-component--jstree-base();

@include m-build-component--jstree-skin(light);