//
//** Right Aside
//

//== Base markup
.m-aside-right {
	width: array-get($m-config-aside, aside-right, self, width);
	padding: array-get($m-config-aside, aside-right, self, padding, desktop);
	border-left: 1px solid array-get($m-config-aside, aside-right, self, border-color);
	border-right: 1px solid array-get($m-config-aside, aside-right, self, border-color);
}

// Desktop mode
@include desktop {
	.m-header--fixed .m-aside-right {
		//padding-top: array-get($m-config-header, header, self, default, height, desktop);
	}
}

// Tablet and Mobile mode
@include tablet-and-mobile {
	.m-aside-right {
		border: 1px solid array-get($m-config-aside, aside-right, self, border-color);
		width: 100%;	
		padding: array-get($m-config-aside, aside-right, self, padding, mobile);
		margin-bottom: 20px;
		@include border-radius( 2px );
	}
}