//
//** Bootstrap Session Timeout Component
//

//== Mixins
@mixin m-build-component--bootstrap-session-timeout-base() {
    #session-timeout-dialog {
    	.modal-header {
            .close {
                position: absolute;
                right: 25px;
                top: 2.75rem;
            }

            .modal-title {
                flex: 1 auto;
            }
        }
    }
}

//== Build
@include m-build-component--bootstrap-session-timeout-base();