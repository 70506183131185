//
//** Wizard 5 Component
//

//== Component Base
@mixin m-build-component--wizard-5-base() {
	.m-wizard.m-wizard--5 {
		//== Default Head
		.m-wizard__head {
			margin: 1rem 0 1rem 0;		 

			//== Default Nav
			.m-wizard__nav {				
				.m-wizard__steps {
					margin-top: 5rem;	
					margin-bottom: -0.07rem;				 
					width: 100%;
					display: table;	
					table-layout: fixed;

					.m-wizard__step {
						display: table-cell;						 
						vertical-align: middle;	
						padding-bottom: 3rem;	

						.m-wizard__step-info {	
							padding-left: 2rem;	

							.m-wizard__step-number {									
								text-decoration: none;
								display: table;	
								margin: 0 auto;																

								.m-wizard__step-seq {
									display: table-cell;	
									font-weight: get-font-weight(bolder);
									padding-right: 0.5rem;
									padding-top: 0.2rem;	
									vertical-align: middle;																															
								}

								.m-wizard__step-label {									
									font-weight: get-font-weight(bolder);	
									padding-right: 0.5rem;	
									display: table-cell;
									vertical-align: middle;	
									font-size: get-font-size(regular, '++');								 						
								}

								.m-wizard__step-icon {
									display: table-cell;
									vertical-align: middle;
								}	
							}							 								
						}
					}

					&:last-child {
						padding-right: 0;
					}
				}	
			}
		}

		//== Centered Nav
		&.m-wizard--nav-centered {	
			.m-wizard__head {
				margin: 3rem auto;	
				width: 80%;
			}		 
		}
	}	 

	@include minimal-desktop-and-below {
		.m-wizard.m-wizard--5 {
			.m-wizard__head {				 		 
				position: relative;	
				width: 100% !important;						 				 	 

				.m-wizard__nav { 
					border-bottom: 0;

					.m-wizard__steps {							 
						width: auto;						
						margin: 1.5rem auto 0 auto;					 											 

						.m-wizard__step {							
							padding: 0 0 0.5rem 0;								
							display: block;	
							margin: 1rem 0 2rem 0;	
							width: 100%;						 						 				 
							
							&:last-child {
								margin-bottom: 0;							
							}						 

							.m-wizard__step-info {								 
								padding-left: 0;								 				

								.m-wizard__step-number {
									margin: 0;

									.m-wizard__step-seq {										
										padding-right: 0.5rem;																																																			
									}

									.m-wizard__step-label {										 	
										padding-right: 0.5rem;
										width: 100%;																		 							 						
									}

									.m-wizard__step-icon {
										text-align: right;
										display: block;										 
									}	
								}								 								
							}						 
						}	 
					}
				}				 
			}			 
		}	
	}	 
}						 

//== Component Skin
@mixin m-build-component--wizard-5-skin() {
	$skin: get-default-skin();
	.m-wizard.m-wizard--5 {
		.m-wizard__head {
			.m-wizard__nav {
				border-bottom: 1px solid get-color($skin, panel);

				.m-wizard__steps {
					.m-wizard__step {
						border-bottom: 2px solid transparent;

						.m-wizard__step-info {
							.m-wizard__step-label {
								color: get-color($skin, regular, '----');
							}

							.m-wizard__step-number { 
								.m-wizard__step-seq {
									color: get-color($skin, regular, '----'); 							 
								}

								.m-wizard__step-icon {
									color: #fff;
								}
							}											
						}

						//== Done skin
						&.m-wizard__step--done {
							.m-wizard__step-info {	
								.m-wizard__step-number { 							
									.m-wizard__step-icon {
										color: get-color($skin, regular, '----');	
									}
								}	
							}
						}

						//== Current skin
						&.m-wizard__step--current {						
							.m-wizard__step-info {	
								.m-wizard__step-number { 
									.m-wizard__step-seq {
										color: get-color($skin, regular, '+++++');							 
									}

									.m-wizard__step-label {
										color: get-color($skin, regular, '+++++');									 
									}

									.m-wizard__step-icon {
										color: #fff;
									}
								}							
							}							 							
						}
					}
				}	 	
			} 	
		}		 
	}

	@include minimal-desktop-and-below {
		.m-wizard.m-wizard--5 {
			.m-wizard__head {
				.m-wizard__nav {
					border-bottom: 0;

					.m-wizard__steps {
						.m-wizard__step {
							border-bottom: 2px solid transparent;
						}
					}
				}	
			}
		}
	}	
}

//== Component States
@mixin m-build-component--wizard-5-states() {
	.m-wizard.m-wizard--5 {
		
		@each $name, $color in $m--state-colors {
			//== Wizard states
			&.m-wizard--#{$name} { 				 
				.m-wizard__head {
					//== Steps
					.m-wizard__steps {
						.m-wizard__step {

							//== Done state
							&.m-wizard__step--done {
								 
							}

							//== Current state
							&.m-wizard__step--current {
								border-bottom: 2px solid array-get($color, base);
	 
								.m-wizard__step-info {	
									.m-wizard__step-number { 
										.m-wizard__step-seq {
											color: array-get($color, base); 						 
										}

										.m-wizard__step-label {
											color: array-get($color, base); 								 
										}
									}								
								}							 							
							}
						} 
					}			
				}	   	
			}							 							      
		}
	}
}

//== Build Component Base
@include m-build-component--wizard-5-base();

//== Build Component Skin
@include m-build-component--wizard-5-skin();

//== Build Component States
@include m-build-component--wizard-5-states();

				 