//
//** Error 2 Snippet
//

//== Snippet Base
@mixin m-build-snippet--error-2-base() {
    .m-error-2 {
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover; 

        .m-error_container {
            .m-error_title2 {

                > h1 {                
                    font-size: 6rem;
                    text-align: center;
                    margin-top: 45rem; 
                    font-weight: get-font-weight(bolder);                               
                }
            }

            .m-error_desc {
                font-size: 2.5rem;
                text-align: center; 
                display: block;             
                font-weight: get-font-weight(boldest);          
            }
        } 
    }

    @include mobile() {
        .m-error-2 {
            .m-error_container {          
                .m-error_desc {
                    padding-left: 0.6rem;
                    padding-right: 0.6rem;
                }
            }
        }    
    }          
} 

//== Build Snippet Base
@include m-build-snippet--error-2-base();