@mixin m-component--dropdown--set-bg-color($bg-color, $box-shadow: null) {
    .m-dropdown__wrapper {
        .m-dropdown__inner {
            background-color: $bg-color;
            @if ($box-shadow != null) {
                box-shadow: $box-shadow;
            }
        }        
    }

    @if smooth-arrow() {
        &.m-dropdown--arrow {
            .m-dropdown__arrow {
                color: $bg-color; 
            }
        }
    } @else {
         &.m-dropdown--arrow {
            .m-dropdown__arrow {
                @include m--set-classic-arrow-bg(top, $bg-color);
            }

            &.m-dropdown--up { 
                .m-dropdown__arrow {
                    @include m--set-classic-arrow-bg(bottom, $bg-color);
                }
            }
        }
    }
}

@mixin m-component--dropdown--set-arrow-color($arrow-color: null, $arrow-direction: null) {
    @if smooth-arrow() {
        &.m-dropdown--arrow {
            .m-dropdown__arrow {
                color: $arrow-color; 
            }
        }
    } @else {
        @if $arrow-direction == null {
            &.m-dropdown--arrow {
                .m-dropdown__arrow {
                    @include m--set-classic-arrow-bg(top, $arrow-color);
                }

                &.m-dropdown--up { 
                    .m-dropdown__arrow {
                        @include m--set-classic-arrow-bg(bottom, $arrow-color);
                    }
                }
            }
        } @else {
            &.m-dropdown--arrow {
                .m-dropdown__arrow {
                    @include m--set-classic-arrow-bg($arrow-direction, $arrow-color);
                }
            }
        }
    }
}

@mixin m-component--dropdown--set-width($width) {
    .m-dropdown__wrapper {
        width: $width;
    }
}