//
//** Demo2 Stylesheets
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
//== 1: Framework Config
@import "framework-config";  // demo level framework config override
@import "../../framework/config"; // metronic framework config
@import "../../framework/vendors/bootstrap/variables"; // metronic bootstrap override config

//== 2: Framework Components Config
@import "../../framework/components/base/config";
@import "../../framework/components/general/config";

//== 3: Bootstrap Include
@import "../../framework/vendors/bootstrap/bootstrap.scss";

//== 4: Framework Components
@import "../../framework/include";

//== 5: Snippets
@import "../../snippets/include";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

//== 6: Demo Config
@import "demo-config";
@import "base/_config";
@import "header/_config";
@import "aside/_config";
@import "menu-header/_config";
@import "menu-aside/_config";   
@import "footer/_config";

//== 7: Demo Layout
@import "base/page";
@import "base/body";

@import "header/brand";
@import "header/header";
@import "header/topbar";
@import "header/search";

@import "aside/aside-left";
@import "aside/aside-right";
@import "aside/aside-header-menu-mobile";

@import "menu-header/menu";
@import "menu-aside/menu";

@import "subheader/default";

@import "footer/default";