//
//** Error 5 Snippet
//

//== Snippet Base
@mixin m-build-snippet--error-5-base() {
    .m-error-5 {
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover; 
        
        .m-error_container {
            .m-error_title {

                > h1 {                
                    font-size: 14rem;
                    margin-left: 25rem;
                    margin-top: 18rem;               
                    font-weight: get-font-weight(boldest);                                   
                }
            }

            .m-error_subtitle {
                margin-left: 26rem;
                margin-top: 3.57rem;
                font-size: 2.3rem;
                font-weight: get-font-weight(boldest);
            }
            .m-error_description {
                margin-left: 26rem;
                font-size: 1.8rem;
                font-weight: get-font-weight(bolder);
                line-height: 130%;
            }
        }
    }

    @include minimal-desktop() {
        .m-error-5 {
            background-position: bottom -80px left 1300px;

            .m-error_container {              
                .m-error_title {                    

                    > h1 { 
                        font-weight: get-font-weight(boldest);               
                        font-size: 12rem;
                        margin-left: 7rem;                                  
                    }
                }

                .m-error_subtitle {                                         
                    margin-left: 7rem;                    
                    font-size: 2.3rem;
                    font-weight: get-font-weight(boldest);
                }             

                .m-error_description {                                         
                    margin-left: 7rem;
                    font-size: 1.8rem;
                    font-weight: get-font-weight(bolder);
                    line-height: 130%;                    
                }
            }
        }  
    }

    @include tablet {
        .m-error-5 {             
            .m-error_container {              
                .m-error_title {                    

                    > h1 { 
                        font-weight: get-font-weight(boldest);               
                        font-size: 12rem;
                        margin-left: 7rem;                                  
                    }
                }

                .m-error_subtitle {                                         
                    margin-left: 7rem;                    
                    font-size: 2.3rem;
                    font-weight: get-font-weight(boldest);
                }             

                .m-error_description {                                         
                    margin-left: 7rem;
                    font-size: 1.8rem;
                    font-weight: get-font-weight(bolder);
                    line-height: 130%;                    
                }
            }
        }  
    } 

    @include mobile() {        
        .m-error-5 {             
            .m-error_container {                 

                .m-error_title {
                     

                    > h1 {                
                        font-size: 6rem;
                        margin-top: 5rem;
                        margin-left: 4rem;                                                        
                    }
                }

                .m-error_subtitle {
                    margin-top: 2rem; 
                    margin-left: 4rem;                      
                    font-size: 2rem;
                    line-height: 2rem;
                     
                }             

                .m-error_description {                                         
                    font-size: 1.4rem;  
                    margin-left: 4rem;                     
                     
                }
            }
        }  
    }              
}

//== Snippet Skin
@mixin m-build-snippet--error-5-skin() {     
    $skin: get-default-skin();
    .m-error-5 {    
        .m-error_container {

            .m-error_title {

                > h1 {
                    color: #314DA7; 
                    -webkit-text-stroke-color: white;                 
                }
            }

            .m-error_subtitle {
                color: get-color($skin, regular, '++++'); 
            }

            .m-error_description {
                color: get-color($skin, regular, '++');
            }
        }
    }         
}

//== Build Snippet Base
@include m-build-snippet--error-5-base();

//== Build Snippet Base
@include m-build-snippet--error-5-skin();
 