//
//** Footer
//

//== Footer Base
@mixin m-build-footer-default-base($layout) {
    //== General mode
    .m-footer {
        height: array-get($layout, default, self, height);
		min-height: array-get($layout, default, self, height);
		background: array-get($layout, default, self, bg-color);
		@include shadow(array-get($layout, default, self, shadow));

		.m-footer__wrapper {
			height: 100%;
			position: relative;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}	

		.m-footer__copyright {
			font-size: array-get($layout, default, copyright, font-size);
			font-weight: array-get($layout, default, copyright, font-weight);
			color: array-get($layout, default, copyright, color);
		}
    }

    //== Desktop mode
    @include desktop {
    	.m-footer--fixed {
	    	.m-body {
	    		padding-bottom: array-get($layout, default, self, height);
	    	}

	    	.m-content {
	    		padding-bottom: array-get($layout, default, self, height);
	    	}
	    }

    	.m-footer {
			.m-footer--fixed & {
				z-index: array-get($layout, fixed, zindex);
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0; 	
			}
	    }

	    //== Pushed footer
	    .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) {         
	    	.m-footer {
	    		background: transparent;
	    		@include shadow(none);
				margin-top: -(array-get($m-config-footer, default, self, height));

	    		.m-footer__wrapper {
	    			background: array-get($layout, default, self, bg-color);
					@include shadow(array-get($layout, default, self, shadow));
	    			@include transition(array-get($m-config-aside, aside-left, self, minimize, transition));
					margin-left: array-get($m-config-aside, aside-left, self, default, width);
					padding-left: array-get($m-config-base, body, wrapper, space);
	    		}	    		
	    	}

	    	&.m-aside-left--minimize  {
				.m-footer {
					.m-footer__wrapper {
						@include transition(array-get($m-config-aside, aside-left, self, minimize, transition));
						margin-left: array-get($m-config-aside, aside-left, self, minimize, width);
					}
				}  
	    	}	    

	    	.m-aside-right,  
	    	.m-wrapper {
	    		margin-bottom: array-get($m-config-footer, default, self, height);
	    	}	

	    	&.m-page--wide.m-aside-left--fixed {
	    		.m-footer {
	    			.m-footer__wrapper {
	    				margin-left: array-get($m-config-aside, aside-left, self, default, width) - 30px;
	    			}
	    		}
	    	}
	    }
    }	

    //== Minimal desktop
    @include minimal-desktop {
    	.m-footer {
    		.m-footer__nav {
    			.m-nav__item {
    				padding: 0 0 0 10px;

    				&:first-child {
    					padding-left: 0;	
    				}

    			}
    		}
    	}
    }

    //== Mobile & tablet mode
    @include tablet-and-mobile {
        .m-footer {
        	padding: array-get($layout, default, self, padding, mobile);
			height: auto;

			.m-stack__item {
				text-align: center;
				padding: 5px 0 5px 0;
			}

			.m-footer__nav {
				text-align: center;
				float: none;
				margin: 0 auto;
			}
		}
    }
}
//== Build Footer Base
@include m-build-footer-default-base( $m-config-footer );