//
//** Horizontal Menu Mixins
//

//== Build Horizontal Menu
@mixin m-build--menu-hor($config) {
	@include m-populate--menu-hor-base($config);

	@include m-populate--menu-hor-skin(array-get($config, base), array-get($config, build, skins, light), light);

	@include m-populate--menu-hor-skin(array-get($config, base), array-get($config, build, skins, dark), dark);
}

//== Generate Horizontal Menu
@mixin m-generate--menu-hor($config) {
	// Base vertical menu
	@if (array-get($config, base, mode) == general) {
		
		@include m-build--menu-hor($config); 		

	} @else if (array-get($config, base, mode) == desktop) {

		@include desktop {
			@include m-build--menu-hor($config);
		}

		@include m-populate--menu-hor-submenu-width($config);

	} @else if (array-get($config, base, mode) == desktop-and-tablet) {		

		@include desktop-and-tablet {
			@include m-build--menu-hor($config);
		}			
	}	
	
	//== Horizontal Menu Hoverable Submenu Animations
	@include keyframes(#{array-get($config, base, class)}-submenu-fade-out) {
	    from   { opacity: 1; }
	    to { opacity: 0; }
	}

	@include keyframes(#{array-get($config, base, class)}-submenu-fade-in) {
	    from   { opacity: 0; }
	    to { opacity: 1; }
	}

	@include keyframes(#{array-get($config, base, class)}-submenu-move-up) {
	    from   { margin-top: array-get($config, build, layout, item, submenu, self, animation, offset); }
	    to { margin-top: 0; }
	}

	@include keyframes(#{array-get($config, base, class)}-submenu-arrow-move-up) {
	    from   { margin-bottom: -(array-get($config, build, layout, item, submenu, self, animation, offset)); }
	    to { margin-bottom: 0; }
	}

	@include keyframes(#{array-get($config, base, class)}-submenu-move-down) {
	    from   { margin-bottom: array-get($config, build, layout, item, submenu, self, animation, offset); }
	    to { margin-bottom: 0; }
	}
}